<template>
    <div>
        <vx-card>
            <vue-bootstrap4-table :rows="data" :columns="columns" :config="config" :actions="actions"   @on-create-reimbursement="onClickCreateReimbursement" columnSelection>

                <template slot="global_search-after" >
                    <div class="ml-4 mt-2 flex">
                        <vs-switch color="success" v-model="showFinishedData" @change="fetchData()"/> <p class="ml-2">Abgeschlossene Daten auch anzeigen </p>
                    </div>
                </template>

                <template slot="row_actions" slot-scope="props">
                    <div>
                      <vx-tooltip text="Bearbeiten" class="w-1" position="left">
                          <vs-button color="warning" type="filled" icon="edit" size="small"
                                     @click="onClickEditReimbursement(props.row)"></vs-button>
                      </vx-tooltip>
                    </div>
                    <div>
                      <vx-tooltip text="Erledigt - an Personalabrechnung übergeben" class="w-1" position="left">
                          <vs-button color="success" type="filled" icon="check" size="small"
                                     @click="markAsTransferred(props.row)"></vs-button>
                      </vx-tooltip>
                    </div>
                    <div>
                      <vx-tooltip text="Löschen" class="w-1" position="left">
                          <vs-button color="danger" type="filled" icon="delete" size="small"
                                     @click="onClickDelete(props.row)"></vs-button>
                      </vx-tooltip>
                    </div>
                    <!-- ToDo -->
                </template>
                <template slot="sum" slot-scope="props">
                    <b>{{ parseCurrency(props.row.sum) }} €</b>
                </template>
                <template slot="category" slot-scope="props">
                    <div class="badge" v-if="props.row.categories && props.row.categories[0] && props.row.categories[0].name">{{ props.row.categories[0].name }}</div>
                    <div class="badge" v-else><i class="text-danger">Bitte auswählen</i> </div>
                </template>
                <template slot="status" slot-scope="props">
                    <vs-select v-model="props.row.status" class="w-full pr-3" @change="setItemStatus(props.row)"   :success="props.row.status == 2"    :danger="props.row.status == 1 || props.row.status == 3">
                        <vs-select-item value="1" text="Freigabe ausstehend" />
                        <vs-select-item value="2" text="Freigegeben" />
                        <vs-select-item value="3" text="OnHold / in Klärung" />
                    </vs-select>

                </template>
                <template slot="username" slot-scope="props">
                  <span class="vbt-table-td">
                    <div>
                      <b>{{ props.row.user.name }}</b><br>
                    </div>
                  </span>

                </template>
                <template slot="month" slot-scope="props">
                  <span>
                      {{ props.row.month }} / {{ props.row.year}}
                  </span>
                </template>

                <template slot="elements" slot-scope="props">
           <span class="d-block" v-for="document in props.row.bank_transaction_documents" :key="document.id">
              <small>Beleg:</small> <a :href="document.file_url" target="_blank" title="Beleg in neuem Tab öffnen">{{ document.original_filename }}</a>
           </span>
                    <span class="d-block" v-if="props.row.client">
            <small>Lieferant:</small> <router-link :to="'/customers/'+props.row.client_id" target="_blank"  >{{props.row.client.company_name}}</router-link>
           </span>
                    <span class="d-block" v-if="props.row.order_id">
            <router-link :to="'/offers/'+props.row.order_id+'/preview'" v-if="props.row.order && props.row.order.offer_billing_type_id && props.row.order.offer_billing_type_id === 4" target="_blank" >Angebot {{props.row.order.invoice_number}}</router-link>
            <router-link :to="'/invoices/view/'+props.row.order_id" v-if="props.row.order && props.row.order.offer_billing_type_id && props.row.order.offer_billing_type_id === 7" target="_blank" >Rechnung {{props.row.order.invoice_number}}</router-link>
            <router-link :to="'/invoices/view/'+props.row.order_id" v-if="props.row.order && props.row.order.order_type_id === 11" target="_blank" >Gutschrift {{props.row.order.invoice_number}}</router-link>
           </span>
                </template>

                <template slot="empty-results">
                    Keine Überweisungen im nächsten Rechnungslauf vorgemerkt.
                </template>
            </vue-bootstrap4-table>
        </vx-card>


        <create-reimbursement-modal :active.sync="openBankTransferModalActive" :selected-reimbursement="selectedReimbursement" @close="closeBankTransferModal()" @saved="closeBankTransferModal(true)"></create-reimbursement-modal>


    </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from '../../../api';

import moment from 'moment';
import qs from "qs";
import {mapGetters} from "vuex";
import QueryHelper from "@/mixins/helper/query.helper";
import PriceHelper from "@/mixins/helper/price.helper";
import MysqlSelectionPopup from "../../../components/mysql-connection-popup/MysqlSelectionPopup";
import EmployeeBillingPopup from "@/components/employee/EmployeeBillingPopup";
import CreateTransferModal from "@/components/billing-transfer/create-transfer-modal";
import CreateReimbursementModal from "@/components/employee-reimbursement/create-reimbursement-modal.vue";

export default {
    components: {
        CreateReimbursementModal,
        EmployeeBillingPopup,
        VueBootstrap4Table,
        MysqlSelectionPopup,
        CreateTransferModal
    },
    data() {
        return {
            showFinishedData: false,
            openBankTransferModalActive: false,
            selectedReimbursement: [],
            activeMysqlSelectionPrompt: false,
            modalPersonalBilling: false,
            data: [],
            actions: [

              {
                    btn_text: "Neuer Kostenersatz",
                    event_name: "on-create-reimbursement",
                    color: 'success'
                }
            ],
            columns: [
                {
                    label: "ID",
                    name: "id",
                    filter: {
                        type: "simple",
                        placeholder: "ID suchen..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    width:'3%',
                    showCol: false,
                },

                {
                    label: "Typ",
                    name: "categories[0].name",
                    slot_name: "category",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    width:'7%',
                },{
                    label: "Empfänger",
                    name: "user.detail.first_name",
                    slot_name: "username",
                    filter: {
                        type: "simple",
                        placeholder: "Name suchen..."
                    },
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Betrag",
                    name: "sum",
                    slot_name: "sum",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    formatter: PriceHelper.formatCurrency,
                    showCol: true,
                },
                {
                    label: "Abrechnungsmonat",
                    name: "month",
                    slot_name: "month",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Title",
                    name: "title",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },{
                    label: "Interner Hinweis",
                    name: "internal_comment",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "Verweise",
                    name: "order_id",
                    slot_name: 'elements',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    width:'15%',
                },
                {
                    label: "Erstellt am",
                    name: "created_at",
                    formatter: this.formatDate,
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                },
                {
                    label: "SEPA XML erstellt am",
                    name: "sepa_xml_export_date",
                    formatter: this.formatDate,
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: false,
                },
                {
                    label: "Status",
                    name: "status",
                    slot_name: "status",
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    showCol: true,
                    width:'15%',
                },
                {
                    label: "Actions",
                    slot_name: "row_actions",
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'actions-column',
                    showCol: true,
                }],
            config: {
                checkbox_rows: true,
                rows_selectable: true,
                page: 1,
                per_page: 50,
                show_refresh_button: false,
                show_reset_button: false,
                highlight_row_hover_color: '#f8f8f8'
            },
            documentQueryParams: {
                sort: [],
                filters: [],
                global_search: "",
                per_page: 100,
                page: 1,
            },

        }
    },
    created() {
        if(!this.userHasPermission('user_can_manage_reimbursements')) {
            this.$vs.notify({
                title: 'Keine Berechtigung',
                text: 'Für diese Seite hast du keine Berechtigung',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            })
            this.$router.push(`/`);
        }
        if(this.userHasPermission('user_can_manage_reimbursements')) {
            this.fetchData();
        }
    },
    computed: {
        ...mapGetters(['userHasPermission']),
        requestDocumentParams() {
            let queryParams = QueryHelper.parseRequestParams(this.documentQueryParams);
            if (!this.showFinishedData){
                queryParams.filter.push({
                    field: 'completed',
                    value: '0',
                    expression: 'exact'
                });
            }
            queryParams.order =
                {
                    'field': 'created_at',
                    'direction': 'desc'
                }
            ;

            return queryParams;
        },


    },
    methods: {
        parseCurrency(value){
            return PriceHelper.formatCurrency(value)
        },
        dateFilterValidator(column, value) {
        },
        fetchData() {
            this.$vs.loading()

            let bankTransactionsDocumentsPromise = ApiService.get('employee-reimbursement', {
                params: this.requestDocumentParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            });

            Promise.all([bankTransactionsDocumentsPromise])
                .then(([bankTransactionsDocuments]) => {
                    this.data = bankTransactionsDocuments.data.result;
                    this.$vs.loading.close();
                }).catch((response) => {

                this.$vs.loading.close();
                this.activeMysqlSelectionPrompt = true;

            });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            } else {
                return "";
            }

        },
        onMysqlSelectionPromptClose() {
            this.activeMysqlSelectionPrompt = false;
            this.fetchData();
        },
        onClickCreateReimbursement(){
            this.openBankTransferModalActive = true
            this.selectedReimbursement = null
        },
        onClickEditReimbursement(reimbursement){
            this.selectedReimbursement = reimbursement
            this.openBankTransferModalActive = true
        },
        closeBankTransferModal(saved = false) {
            this.openBankTransferModalActive = false;
            this.selectedReimbursement = null
            if (saved) {
                this.fetchData()
            }

        },
        onExportCsv(){
            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: 'SEPA XML Download',
                text: "Bist du dir sicher, dass du die aktuelle SEPA XML downloaden möchtest? Du musst wissen, das alle - mit STATUS -> Freigegeben - Überweisungen dann auf erledigt gesetzt werden und in der nächsten SEPA-Datei nicht mehr erscheinen.      -----> Nur freigegebe Überweisungen!!",
                accept: this.downloadSepaXML,
                acceptText: 'Ja, SEPA XML generieren',
                cancelText: 'Abbrechen'
            })
        },
        openPersonalBillingModal(){
            this.modalPersonalBilling = true;
        },
        onPersonalPromptClose(){
            this.modalPersonalBilling = false;
        },
        onClickDelete(item){
            ApiService.delete('employee-reimbursement/'+item.id).then((response) => {

                if (response.data.status == "success") {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Überweisung wurde erfolgreich gelöscht.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });
                    this.fetchData()


                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Fehler',
                        text: "Überweisung konnte nicht gelöscht werden",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    this.fetchData()
                }

            }).catch((error) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: error.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
                this.fetchData()
            });
        },
        markAsTransferred(item){
            var now = moment();
            const payload = {
                "completed": '1'
            };


            ApiService.put('employee-reimbursement/'+item.id, payload).then((response) => {

                if (response.data.status == "success") {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Kostenerstattung wurde erfolgreich bearbeitet.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });
                    this.fetchData()


                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Fehler',
                        text: "Kostenerstattung konnte nicht bearbeitet werden",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    this.fetchData()
                }

            }).catch((error) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: error.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
                this.fetchData()
            });


        },
        setItemStatus(item){

            const payload = {
                "status": item.status
            };

            ApiService.put('employee-reimbursement/'+item.id, payload).then((response) => {

                if (response.data.status == "success") {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Kostenerstattung wurde erfolgreich bearbeitet.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });


                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Fehler',
                        text: "Kostenerstattung konnte nicht bearbeitet werden",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    this.fetchData()
                }

            }).catch((error) => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: error.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
                this.fetchData()
            });




        },
        downloadSepaXML(){
            ApiService.get('bank-transactions/document/billing-run',{ responseType: 'blob' }).then(response => {

                let blob = new Blob([response.data], { type: 'application/xml' })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'mediagraphik-sepa.xml'
                link.click()

            })
        },
    },
    mounted() {
    }
}

</script>

<style lang="scss">
.input-select-validate-success .input-select-con .input-select {
  color:#71c73f;
  font-weight: 600;
}
.input-select-validate-danger .input-select-con .input-select {
  color:#ea3540;
  font-weight: 600;
}
</style>
