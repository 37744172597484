<template>
    <div class="billing-transfer-modal">
        <vs-popup class="billing-transfer-modal--popup" :title="popupTitle" @close="onClose"
                  :active="visible">
            <div v-show="data">

                <div class="vx-row mb-2 p-4">
                    <div class="vx-col  w-full mb-0">

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Empfänger:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col vs-w="6">
                                        <mg-select v-model="data.user_id" :options="employees" name="user_id"
                                                   placeholder="Empfänger"
                                                   track-by="id" label="name" data-vv-as="Empfänger">
                                        </mg-select>
                                        <span class="text-danger text-sm" v-if="errors.has('user_id')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Auszahlungsmonat:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col vs-w="6">
                                        <vs-input v-model="data.year" placeholder="Jahr" name="year" class="mb-2 w-full"  data-vv-as="IBAN" v-validate="'required'" />
                                        <span class="text-danger text-sm" v-if="errors.has('year')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                    <vs-col  vs-w="6" class="pl-4">
                                        <vs-input v-model="data.month" placeholder="Monat" name="month" class="mb-2 w-full" v-validate="'required'"/>
                                        <span class="text-danger text-sm " v-if="errors.has('month')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>

                      <vs-row class="mt-4">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                          <label>Betrag:</label>
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                          <vs-row>
                            <vs-col vs-w="6">
                              <vs-input v-model="data.sum" placeholder="Summe" v-validate="'required'"
                                        name="sum"
                                        class="mb-2 w-full"
                                        v-currency="{
                                              currency: 'EUR',
                                              locale: 'de',
                                              autoDecimalMode: false,
                                              decimalLength: 2,
                                              min: 0,
                                     }"
                              />
                              <span class="text-danger text-sm" v-if="errors.has('sum')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                            <vs-col  vs-w="6" class="pl-4">

                            </vs-col>
                          </vs-row>
                        </vs-col>
                      </vs-row>

                      <vs-row class="mt-4">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                          <label>Buchungstext:</label>
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                          <vs-row>
                            <vs-col vs-w="6">
                              <vs-textarea v-model="data.title" placeholder="Buchungstext" v-validate="'required'"
                                           name="title" class="mb-2 w-full"/>
                              <span class="text-danger text-sm" v-if="errors.has('title')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                            <vs-col vs-w="6" class="pl-4">
                              <vs-textarea v-model="data.internal_comment" placeholder="Interne Notiz"
                                           name="internal_comment" class="mb-2 w-full"/>
                              <span class="text-danger text-sm " v-if="errors.has('internal_comment')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                          </vs-row>
                        </vs-col>
                      </vs-row>

                        <div >
                            <label><b>Kategorie:</b></label><br>
                            <category-selector v-model="data.categories" type="employee_reimbursement" :multiple="false" ></category-selector>
                        </div>

                    </div>
                </div>
            </div>





            <div class="flex p-2 justify-end bg-gray-200 rounded-b">
                <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
                <vs-button @click="createData" :disabled="!formValid" v-if="!isEdit">Neue Überweisung hinzufügen</vs-button>
                <vs-button @click="updateData" :disabled="!formValid" v-if="isEdit">Überweisung speichern</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";
    import MgSelect from "../../components/mg-select/MgSelect";
    import * as qs from "qs";
    import moment from 'moment';
    import PriceHelper from "../../mixins/helper/price.helper";
    import {mapGetters} from "vuex";
    import CategorySelector from "@/components/search/CategorySelector.vue";

    export default {
        name: "create-reimbursement-modal",
        components: {
            CategorySelector,
            MgSelect
        },
        props: {
            selectedReimbursement: {
                default: null
            },
            active: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                data: {},
                employees: [],
                newData: {
                    "user_id": null,
                    "year": null,
                    "month": null,
                    "title": null,
                    "sum": null,
                    "internal_comment": null,
                    "status": 1,
                    "completed": 0,
                    "categories": []
                },
            }
        },
        created() {


        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),
            visible() {
                return this.active;
            },
            formValid() {
              return !this.errors.any();
            },
            isEdit() {
                return this.selectedReimbursement !== null;
            },
            popupTitle() {
                if (this.isEdit) {
                    return 'Kostenersatz bearbeiten'
                }

                return 'Kostenersatz anlegen'
            }
        },
        methods: {
            openModal() {
              this.fetchData();
            },
            fetchData() {

              this.fetchUsers();

                if (this.selectedReimbursement) {
                  this.data.user_id = this.selectedReimbursement.user_id;
                  this.data.year = this.selectedReimbursement.year;
                  this.data.title = this.selectedReimbursement.title;
                  this.data.sum = PriceHelper.floatPointToComma(this.selectedReimbursement.sum.toString());
                  this.data.internal_comment = this.selectedReimbursement.internal_comment;
                  this.data.status = this.selectedReimbursement.status;
                  this.data.completed = this.selectedReimbursement.completed;
                  this.data.month = this.selectedReimbursement.month;
                  this.data.categories = this.selectedReimbursement.categories;
                } else {

                    this.data = JSON.parse(JSON.stringify(this.newData));
                    this.data.year = moment().format('YYYY');
                    this.data.month = moment().format('MM');
                }

            },
            fetchUsers() {
                ApiService.get('users').then(response => {
                    if (response.status !== 200) {
                        return;
                    }

                    this.employees = response.data.result

                }).catch(response => {

                })
            },
            onClose() {
                this.$emit('update:active', false)
                this.$emit('close')
            },
           onSave() {
                this.$emit('update:active', false)
                this.$emit('saved')
            },
            updateData(){

              this.$validator.validate().then(valid => {
                  if (valid) {

                      const payload = JSON.parse(JSON.stringify(this.data));
console.log(payload)
                      payload.sum = payload.sum.replace("€", "");
                      payload.sum = payload.sum.replace(/\s+/g, '');
                      payload.sum = PriceHelper.parseCommaFloat(payload.sum);

                      ApiService.put('employee-reimbursement/' + this.selectedReimbursement.id, payload).then((response) => {

                          if (response.data.status === "success") {
                              this.onSave();
                              this.$vs.loading.close();
                              this.$vs.notify({
                                  title: 'Erfolgreich',
                                  text: 'Der Kostenersatz wurde erfolgreich aktualisiert',
                                  iconPack: 'feather',
                                  icon: 'icon-alert-circle',
                                  color: 'success'
                              });
                          }
                      }).catch((error) => {
                          this.$vs.loading.close();
                          this.$vs.notify({
                              title: 'Fehler',
                              text: error.message,
                              iconPack: 'feather',
                              icon: 'icon-alert-circle',
                              color: 'danger'
                          })
                      });
                  } else {
                      this.$vs.notify({
                          title: 'Fehlgeschlagen',
                          text: this.$validator.errors.all(),
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      });
                  }
              })
            },
            createData() {

                this.$validator.validate().then(valid => {
                    if (valid) {

                      const payload = JSON.parse(JSON.stringify(this.data));

                      payload.sum = payload.sum.replace("€","");
                      payload.sum = payload.sum.replace(/\s+/g, '');
                      payload.sum = PriceHelper.parseCommaFloat(payload.sum);

                        ApiService.post('employee-reimbursement', payload).then((response) => {

                          if (response.data.status === "success") {
                              this.onSave();
                            this.$vs.loading.close();
                            this.$vs.notify({
                              title: 'Erfolgreich',
                              text: 'Der Kostenersatz wurde erfolgreich angelegt',
                              iconPack: 'feather',
                              icon: 'icon-alert-circle',
                              color: 'success'
                            });


                          } else {
                            this.$vs.loading.close();
                            this.$vs.notify({
                              title: 'Fehler',
                              text: "Kostenersatz konnte nicht angelegt werden",
                              iconPack: 'feather',
                              icon: 'icon-alert-circle',
                              color: 'danger'
                            });
                          }

                        }).catch((error) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Fehler',
                                text: error.message,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        });
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: this.$validator.errors.all(),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                })
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },

        },
        watch: {
            active: function (val) {
                if (val === true) {
                    this.openModal();
                }
            },
        }
    }
</script>

<style lang="scss">
    .billing-transfer-modal--popup {
        .vs-popup {
            min-width: 700px;
            max-width: 100vw;
        }

        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;
        }
      .documentprice-badge {
        top: -8px;
        position: relative;
        font-size: 10px;
      }
    }
</style>
